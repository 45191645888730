import React from "react"
import { graphql } from "gatsby"

import Moment from "react-moment"
import SEO from "../components/seo"

import Layout from "../components/layoutGeneric"
import MediaLeftList from "../components/listings/media-left-list"
import MediaTopList from "../components/listings/media-top-list"

export const query = graphql`  
  query Category($slug: String!) {
    site: site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    # articles: allStrapiArticles(filter: { category: { slug: { eq: $slug } } }) {
      articles:allStrapiArticles(limit: 15, skip: 0, sort: {fields: createdAt, order: ASC}, filter: {status: {eq:"published"}, category: { slug: { eq: $slug } }}){ 
      edges {
          node {
            slug
            strapiId
            id
            excerpt
            createdAt
            title
            isFeatured
            category {
              name
            }
            banner {
              childImageSharp {
                fixed(width: 400, height: 250, toFormat: JPG) {
                  srcSet
                  width
                  height
                  src
                  aspectRatio
                }
              }
            }
          }
        }
    }
    category: strapiCategories(slug: { eq: $slug }) {
      name
    }
    recentNews:allStrapiArticles(limit: 5, skip: 0, sort: {fields: createdAt, order: ASC}, filter: {status: {eq:"published"}, slug: {ne: ""}, 
      category: {slug: {eq: "news"}}
      }) {
        edges {
          node {
            slug
            strapiId
            id
            excerpt
            createdAt
            title
            isFeatured
            category {
              name
            }
            banner {
              childImageSharp {
                fixed(width: 200, height: 160, toFormat: JPG) {
                  srcSet
                  width
                  height
                  src
                  aspectRatio
                }
              }
            }
          }
        }
      }
      recentFeatures: allStrapiArticles(limit: 5, skip: 0, sort: {fields: createdAt,order: ASC}, filter: {status: {},
      # isFeatured:{eq:false},
        slug: {ne: ""}, category: {slug: {eq: "custom-motorcycle"}}}) {
        edges {
          node {
            slug
            strapiId
            id
            excerpt
            createdAt
            title
            isFeatured
            category {
              name
            }
            banner {
              childImageSharp {
                fixed(width: 400, height: 250, toFormat: JPG) {
                  srcSet
                  width
                  height
                  src
                  aspectRatio
                }
              }
            }
          }
        }
      }
  }
`

const Category = ({ data }) => {
  const site = data.site.siteMetadata
  const articles = data.articles
  const category = data.category.name
  const recentNews = data.recentNews
  const recentFeatures = data.recentFeatures

  return (
    <Layout>
      <SEO
        title={category.name}
        description={'Moto Minuto'}
      // image={image}
      // pathname={this.props.location.pathname}
      />
      <div className="uk-section">
        <div className="uk-container uk-container-medium">
          <div className="uk-grid-divider uk-grid" uk-grid="">
            <div className="uk-first-column uk-width-2-3@m  uk-width-1-1@s">
              <h2>{category}</h2>
              <MediaTopList
                data={articles}
                title=""
                columns={2}
              />
            </div>
            <div className="uk-first-column uk-width-1-3@m  uk-width-1-1@s">
              <MediaLeftList
                data={recentNews}
                title="NEWS"
              />
              <MediaLeftList
                data={recentFeatures}
                title="FEATURES"
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Category  